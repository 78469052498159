.error {
  border: 1px solid red;
}

.enabled {

}


input.currency {
  text-align: right;
}

.disabled {
  display: none;
}
